/** @jsxRuntime classic */
/** @jsx jsx */
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql, navigate, PageProps } from "gatsby"
import { jsx, Box, Grid, Container } from "theme-ui"

import type { AllNewsPageQuery } from "../../graphql-types"

import BlogCard from "components/BlogCard"
import BlogNav from "components/BlogNav"
import Layout from "components/Layout"
import PageHero from "components/PageHero"
import Pagination from "components/Pagination"

interface AllNewsPageProps extends PageProps {
  data: AllNewsPageQuery
  pageContext: {
    currentPage: number
    limit: number
    numPages: number
    skip: number
  }
}

const AllNewsPage = ({ data, pageContext, ...props }: AllNewsPageProps) => {
  const articleTypeLinks = data.allStrapiArticleType.edges.map(article => ({
    text: article.node.name as string,
    href: `/news/${article.node.slug}#blog-nav` as string,
  }))

  const handlePagination = (e: any) => {
    const pageSelected = e.selected
    const url = `/news/${pageSelected + 1}#blog-nav`
    pageSelected === 1 ? navigate(url) : navigate(`/news#blog-nav`)
  }

  const currentPath = `${props.path}#blog-nav`

  return (
    <Layout seo={data.strapiNewsPage?.seo} location={props.location}>
      <PageHero
        title={data.strapiNewsPage?.Hero?.title as string}
        subtitle={data.strapiNewsPage?.Hero?.subtitle as string}
        content={data.strapiNewsPage?.Hero?.content as string}
        hasLines={data.strapiNewsPage?.Hero?.has_lines as boolean}
        hasLocatorCTA={data.strapiNewsPage?.Hero?.has_locator as boolean}
        imagePosition={data.strapiNewsPage?.Hero?.image_position as string}
        image={
          data.strapiNewsPage?.Hero?.image?.localFile?.childImageSharp
            ?.gatsbyImageData as IGatsbyImageData
        }
        imageAlt={data.strapiNewsPage?.Hero?.image?.alternativeText}
        buttonOneUrl={
          data.strapiNewsPage?.Hero?.hero_links?.[0]?.link_button_url as string
        }
        buttonOneText={
          data.strapiNewsPage?.Hero?.hero_links?.[0]?.link_button_text as string
        }
        buttonTwoUrl={
          data.strapiNewsPage?.Hero?.hero_links?.[1]?.link_button_url as string
        }
        buttonTwoText={
          data.strapiNewsPage?.Hero?.hero_links?.[1]?.link_button_text as string
        }
      />

      <BlogNav currentPath={currentPath} links={articleTypeLinks} isAllPage />

      <Box
        as="section"
        sx={{
          py: [null, null, null, 16],
          my: 16,
          "::target": {
            display: "block",
            position: "relative",
            top: "-400px",
            visibility: "visible",
          },
        }}
      >
        <Container>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
              rowGap: ["25px", null, "50px"],
              columnGap: "22px",
            }}
          >
            {data.allStrapiArticle.edges.map((article, index) => (
              <BlogCard
                key={index}
                srcUrl={`/news/${article?.node?.article_type?.slug}/${article?.node.slug}`}
                date={article.node.Publish_Date}
                title={article.node.title as string}
              />
            ))}
          </Grid>

          {pageContext.numPages > 1 && (
            <Pagination
              forcePage={pageContext.currentPage - 1}
              onPageChange={handlePagination}
              pageCount={pageContext.numPages}
            />
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default AllNewsPage

export const pageQuery = graphql`
  query AllNewsPage($skip: Int!, $limit: Int!) {
    allStrapiArticle(
      skip: $skip
      limit: $limit
      sort: { fields: Publish_Date, order: DESC }
    ) {
      edges {
        node {
          Publish_Date
          title
          slug
          article_type {
            slug
          }
        }
      }
    }
    strapiNewsPage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      Hero {
        hero_links {
          link_button_url
          link_button_text
        }
        title
        content
        has_lines
        has_locator
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_position
        subtitle
      }
    }
    allStrapiArticleType {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`
